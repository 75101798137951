<!-- 
  ******************************************************************
    Loading 아이콘이 표시되며 DOM 선택이 되지않게 처리한 공용 Spinner
   - Global Method 선언이 되어 있으므로 import 필요 X
   - <Spinner :showSpinner="loading"></Spinner> 태그 사용
   - 'loading' boolean Type으로 선언
  ******************************************************************
-->
<template>
  <div v-if="showSpinner" class="spinner-container">
    <div>
      <b-spinner label="Loding.." style="width: 2.75rem; height: 2.75rem; color: rgb(76, 150, 255);"></b-spinner>
    </div>
    <div class="spinner-text-bottom">
      {{ dynamicMessage }}
    </div>
    <div class="spinner-text-bottom2" style="color: rgb(168, 168, 168);">
      {{ infoMessage }}
    </div>
  </div>
  <!-- <div v-else>
    <slot></slot>
  </div> -->
</template>
  
  <script>
  export default {
    props: {
      showSpinner: {
        type: Boolean,
        default: false,
      },
      showMessage : {
        type: String,
        defualt: "로딩중"
      },
      infoMessage : {
        type: String,
        defualt: "(건당 최대 10초 정도 소요됩니다)"
      }
    },
    data() {
      return {
        // data feild
        dynamicMessage: this.showMessage,
        commaCount: 0,
      };
    },
    watch: {
      showSpinner(newVal) {
        if (newVal) {
          this.messageDot();
        } else {
          clearInterval(this.loadingInterval);
        }
      }
    },
    // mounted() {
    //   this.messageDot();
    // },
    methods: {
      // 스피너를 표시할지 숨길지 결정하는 메서드
      showSpinnerMethod(show) {
        this.showSpinner = show;
      },

      // Message에 .(dot) 찍어주기.
      messageDot() {
        this.loadingInterval = setInterval(() => {
          if(this.commaCount === 4) {
            this.dynamicMessage = this.showMessage;
            this.commaCount = 0;
          } else {
            this.commaCount++
            this.dynamicMessage += '.';
          }
        }, 500);
      }
    },
  };
  </script>
  
  <style>
  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* 배경 반투명. */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900; /* 다른 요소 위에 나타나도록 설정 */
  }
  
  .spinner {
    border: 4px solid #f3f3f3; /* 회전하는 원의 테두리 스타일 설정 */
    border-top: 4px solid #3498db; /* 회전하는 원의 윗 부분 색상 설정 */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* 회전 애니메이션 추가 */
  }
  
  .spinner-text-right {
    margin-top: 10px;
    margin-left: 8px;
    text-align: center;
  }

  .spinner-text-bottom {
    margin-top: 84px;
    position: absolute;
  }

  .spinner-text-bottom2 {
    margin-top: 130px;
    position: absolute;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>